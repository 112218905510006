const compare = (d1, d2, field) => {
    const v1 = field(d1);
    const v2 = field(d2);
    if (v1.length === 1 && v2.length === 1) {
        return (String(v1[0]) || "")
            .toLowerCase()
            .localeCompare((String(v2[0]) || "").toLowerCase());
    }
    return v1.length - v2.length;
};
export const sort = (d1, d2, sorting) => {
    let result = compare(d1, d2, sorting.field);
    if (result === 0 && sorting.defaultField !== sorting.field) {
        result = compare(d1, d2, sorting.defaultField);
    }
    return sorting.ascending ? result : -result;
};
