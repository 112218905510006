export const AllServiceBundle = /* GraphQL */ `
  query AllServiceBundle {
    allServiceBundle {
      servers {
        name
        serviceBundle {
          version
          url
        }
      }
      releases {
        created
        url
        version
        body
      }
      deployments {
        env
        deployment {
          version
          depl_time
        }
      }
    }
    getUserGroup(type: "FeatureServiceBundle") {
      featureAppAdmin {
        stage
        int
        prod
      }
    }
  }
`;
export const RegisterFeatureServiceBundle = /* GraphQL */ `
  mutation RegisterFeatureServiceBundle($envName: String!, $url: String!) {
    registerFeatureServiceBundle(envName: $envName, url: $url) {
      success
      msg
    }
  }
`;
export const RegisterFeatureAppConfigurations = /* GraphQL */ `
  mutation RegisterFeatureAppConfigurations(
    $envName: String!
    $configs: [FeatureAppConfigurationUpdate]
  ) {
    registerFeatureAppConfigurations(envName: $envName, configs: $configs) {
      success
      msg
    }
  }
`;
