export const TIMEOUT = 180;
export const reloadFactory = (refetch, setLoading, timeout) => {
    return (check) => {
        setLoading(true);
        let i = 0;
        refetch().then((result) => {
            if (check(result.data)) {
                setLoading(false);
                return;
            }
            const polling = window.setInterval(async () => {
                const result = await refetch();
                i++;
                if (check(result.data)) {
                    window.clearInterval(polling);
                    setLoading(false);
                }
                if (i > TIMEOUT) {
                    window.clearInterval(polling);
                    setLoading(false);
                    timeout(TIMEOUT);
                }
            }, 10000);
        });
    };
};
