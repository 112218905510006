import * as React from "react";
import { StyledTh, StyledTable, StyledTd } from "../component/Table";
import { createTagOverride, CTA, Richtext, styled, } from "@volkswagen-onehub/components-core";
import { getVersionAnchor } from "../component/getVersionAnchor";
function formatDate(dateString) {
    try {
        const date = new Date(dateString);
        return new Intl.DateTimeFormat().format(date);
    }
    catch (e) {
        return dateString;
    }
}
function processRichtext(body) {
    if (!body) {
        return "";
    }
    return body.replace(/(ngwd6-\d+)( -)?/g, (_all, ticketId) => {
        return `[${ticketId}](https://sso.volkswagen.de/bctbt/browse/${ticketId})`;
    });
}
export const ReleasesView = (props) => {
    const { releases } = props;
    return releases && releases.length ? (React.createElement("div", null,
        React.createElement(StyledTable, null,
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement(StyledTh, null, "version"),
                    React.createElement(StyledTh, null, "date"),
                    React.createElement(StyledTh, null, "url"),
                    React.createElement(StyledTh, null, "changes"))),
            React.createElement("tbody", null, releases.map(({ version, tagName, url, body, created }, index) => (React.createElement("tr", { key: index },
                React.createElement(StyledTd, { id: getVersionAnchor(version) },
                    React.createElement(CTA, { tag: "a", emphasis: "tertiary", href: url }, version || tagName)),
                React.createElement(StyledTd, null, formatDate(created)),
                React.createElement(StyledTd, null,
                    React.createElement(CTA, { target: "_blank", tag: "a", emphasis: "tertiary", href: url }, "url")),
                React.createElement(StyledTd, null,
                    React.createElement(Richtext, { overrides: {
                            h1: createTagOverride(Hidden, {}),
                            h2: createTagOverride(Hidden, {}),
                        }, markdown: processRichtext(body !== null && body !== void 0 ? body : undefined) }))))))))) : null;
};
const Hidden = styled.div `
  display: none;
`;
