import React from "react";
import { Container, CTA, Text, TokenTextAppearance, } from "@volkswagen-onehub/components-core";
import { StyledTable, StyledTd, StyledTh } from "./Table";
import { Link } from "./Link";
export function ConfigurationDetailTable(props) {
    const { configurations } = props;
    let rowCount = 0;
    configurations.forEach((c) => { var _a; return (rowCount += Number((_a = c.servers) === null || _a === void 0 ? void 0 : _a.length)); });
    // TODO replace the appName with the groupName from config
    return (React.createElement(Container, { gutter: "dynamic0200", wrap: "always" },
        React.createElement(Text, { appearance: TokenTextAppearance.headline300 }, "Configurations"),
        React.createElement(StyledTable, null,
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement(StyledTh, null, "configuration"),
                    React.createElement(StyledTh, null, "server"),
                    React.createElement(StyledTh, null, "version"),
                    React.createElement(StyledTh, null, "author link"))),
            React.createElement("tbody", null, configurations.map((c) => {
                var _a;
                return (_a = c.servers) === null || _a === void 0 ? void 0 : _a.map((s, sidx) => {
                    return (React.createElement("tr", { key: s.server.name },
                        sidx === 0 && c.servers ? (React.createElement(StyledTd, { rowSpan: c.servers.length }, c.name)) : null,
                        React.createElement(StyledTd, null,
                            React.createElement(Link, { path: `/server/${s.server.name}`, text: s.server.name })),
                        React.createElement(StyledTd, null, s.config.version),
                        React.createElement(StyledTd, null,
                            React.createElement(CTA, { tag: "a", emphasis: "tertiary", href: `${s.server.authorUrl}/editor.html${s.config.path}.html`, rel: "noopener noreferrer", target: "_blank" }, "author"))));
                });
            })))));
}
