import { designTokens } from "@volkswagen-onehub/components-core";
import styled, { css } from "styled-components";
export const StyledTable = styled.table `
  width: 100%;
  empty-cells: show;
  border-collapse: collapse;
  display: table;
`;
export const StyledTd = styled.td `
  padding-left: ${(props) => props.theme.size.static150};
  padding-right: ${(props) => props.theme.size.static150};
  padding-top: ${(props) => props.theme.size.static200};
  padding-bottom: ${(props) => props.theme.size.static200};
  border: 1px solid ${designTokens.color["grey-100"]};
  vertical-align: top;

  ${(props) => props.isSummary &&
    css `
      span {
        font-weight: bold;
      }
    `};

  ${(props) => props.noWrap &&
    css `
      white-space: nowrap;
    `};

  ${(props) => props.noWrap &&
    css `
      white-space: nowrap;
    `};

  ${(props) => props.wordBreak &&
    css `
      word-break: break-all;
    `};

  ${(props) => props.breakWord &&
    css `
      word-break: break-word;
    `};

  ${StyledTable} tbody tr &:first-child {
    border-left: 0;
  }

  ${StyledTable} tbody tr &:last-child {
    border-right: 0;
  }

  ${StyledTable} tbody tr:last-child & {
    border-bottom: 1px solid
      ${(props) => props.layout === "HEADER_CATEGORY_SUM"
    ? designTokens.color["black-000"]
    : designTokens.color["grey-100"]};
  }
`;
export const StyledTh = styled.th `
  padding-left: ${(props) => props.theme.size.static150};
  padding-right: ${(props) => props.theme.size.static150};
  padding-top: ${(props) => props.theme.size.static200};
  padding-bottom: ${(props) => props.theme.size.static250};
  text-align: left;
  vertical-align: top;

  ${StyledTable} tr &:first-child {
    border-left: 0;
  }

  ${StyledTable} tr &:last-child {
    border-right: 0;
  }

  ${StyledTable} tbody tr &:first-child {
    border-right: 2px solid ${designTokens.color["black-000"]};
  }

  ${StyledTable} tbody tr & {
    border-bottom: 1px solid ${designTokens.color["grey-100"]};
  }

  ${StyledTable} thead tr & {
    padding-top: 0;
    border-top: 0;
    border-bottom: 2px solid ${designTokens.color["black-000"]};
  }

  ${StyledTable} tfoot tr & {
    border-bottom: 0;
    border-top: 2px solid ${designTokens.color["black-000"]};
  }

  ${(props) => props.layout === "HEADER_CATEGORY_SUM" &&
    css `
      ${StyledTable} tbody tr:last-child & {
        border-bottom: 0;
      }
    `}

  ${(props) => props.wordBreak &&
    css `
      word-break: break-all;
    `};

  ${(props) => props.layout === "CATEGORY" &&
    css `
      ${StyledTable} tbody tr:first-child & {
        border-top: 1px solid ${designTokens.color["grey-100"]};
      }
    `}
`;
export var rowType;
(function (rowType) {
    rowType["headRowType"] = "headRowType";
    rowType["bodyRowType"] = "bodyRowType";
    rowType["footRowType"] = "footRowType";
})(rowType || (rowType = {}));
export const StyledCol = styled.col `
  width: ${(props) => props.width ? `${props.width}%` : `calc(100% / ${props.columnsCount})`};
`;
