import { format } from "./format";
import { sendMessage } from "./sendMessage";
export const createLogger = (options) => new HttpLogger({
    author: false,
    consumerName: "onehub-manager",
    country: "global",
    language: "global",
    env: options.env,
    loggerName: "main",
    team: "onehub-manager",
    version: options.version,
    url: options.url,
});
export class HttpLogger {
    constructor(options) {
        this.options = options;
    }
    sendHttp(message, level, optionalParams) {
        if (!this.options.url) {
            console.error("no url for http logging configured");
            return;
        }
        const url = window.location.href;
        if (message) {
            const agent = navigator.userAgent;
            const formattedMessage = format(message, ...optionalParams);
            const event = {
                type: "single",
                featureAppId: this.options.consumerName,
                name: this.options.loggerName,
                level,
                timestamp: new Date().getTime(),
                featureAppVersion: this.options.version,
                message: formattedMessage,
                url,
                country: this.options.country,
                language: this.options.language,
                env: this.options.env,
                author: this.options.author,
                team: this.options.team,
                server: false,
                agent,
            };
            sendMessage(this.options.url, event);
        }
    }
    debug(message, ...optionalParams) {
        this.sendHttp(message, "debug", optionalParams);
    }
    error(message, ...optionalParams) {
        this.sendHttp(message, "error", optionalParams);
    }
    info(message, ...optionalParams) {
        this.sendHttp(message, "info", optionalParams);
    }
    trace(_message, ..._optionalParams) {
        // This is intentional
    }
    warn(message, ...optionalParams) {
        this.sendHttp(message, "warn", optionalParams);
    }
}
