import React, { useContext, useEffect, useState, } from "react";
import { Container, ContainerGutter, ContainerPadding, CTA, IntegratorRoot, MovingFrame, ThemeProvider, } from "@volkswagen-onehub/components-core";
import { Logout, ExpressService } from "@volkswagen-onehub/icons-core";
import { ServiceContext } from "./context/ServiceContext";
import { signOut } from "@aws-amplify/auth";
import { getVersion } from "./getVersion";
import { ConfigContext } from "./context/ConfigContext";
import { AppConfigLayer } from "./component/AppConfigLayer";
const ApplicationContainer = (props) => {
    const { children } = props;
    const layerManager = useContext(ServiceContext).layerManager;
    const { config } = useContext(ConfigContext);
    const [layer, setLayer] = useState(null);
    useEffect(() => {
        layerManager.subscribe(() => {
            setLayer(layerManager.layer);
        });
    });
    const handleLogout = async () => {
        try {
            await signOut({ global: true }).then(() => {
                window.location.href = "/";
            });
        }
        catch (err) {
            console.log("error", err);
        }
    };
    const version = getVersion();
    const env = config === null || config === void 0 ? void 0 : config.envName;
    const claim = (React.createElement(CTA, { tag: "a", emphasis: "tertiary", onClick: () => {
            layerManager.openAlert(React.createElement(AppConfigLayer, null));
        } }, `Onehub Manager [Cloud - ${env}] v${version}`));
    return (React.createElement(IntegratorRoot, null,
        React.createElement(ThemeProvider, null,
            React.createElement(Container, { padding: {
                    left: ContainerPadding.grid001,
                    right: ContainerPadding.grid001,
                    top: ContainerPadding.static200,
                }, horizontalAlign: "flex-end" },
                React.createElement(Container, { gutter: ContainerGutter.static350, padding: ContainerPadding.static200, wrap: "never" },
                    React.createElement(CTA, { tag: "button", emphasis: "tertiary", onClick: () => handleLogout(), icon: React.createElement(Logout, null) }, "Logout"))),
            React.createElement(MovingFrame, { claim: claim, logo: React.createElement(CTA, { tag: "button", emphasis: "tertiary", ariaLabel: "aria label", icon: React.createElement(ExpressService, null) }) }),
            React.createElement(Container, { stretchContent: true, wrap: "always", padding: {
                    top: ContainerPadding.static300,
                }, gutter: ContainerGutter.static500 }, children),
            React.createElement("div", null, layer))));
};
export { ApplicationContainer };
