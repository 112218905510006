import * as React from "react";
import { Container, DesignTokenSizeEnum, Spinner, } from "@volkswagen-onehub/components-core";
import { ServiceContext } from "../context/ServiceContext";
import { ErrorBox } from "../container/ErrorBox";
export function Loading(props) {
    const { reloading, render, loading, error } = props;
    const { layerManager } = React.useContext(ServiceContext);
    const [open, setOpen] = React.useState(false);
    React.useEffect(() => {
        if (!reloading && open) {
            layerManager.closeSpinner();
            setOpen(false);
        }
        if (reloading && !open) {
            setOpen(true);
            layerManager.openSpinner();
        }
    }, [reloading, open, setOpen, layerManager]);
    if (loading)
        return (React.createElement(Container, { horizontalAlign: "center", padding: DesignTokenSizeEnum.dynamic0350 },
            React.createElement(Spinner, { variant: "x-large" })));
    if (error)
        return (React.createElement(Container, null,
            React.createElement(ErrorBox, { text: `${error.message}` })));
    return React.createElement(React.Fragment, null, render());
}
