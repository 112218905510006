import { useState } from "react";
export function useSort() {
    const [sortIndex, setSortIndex] = useState(0);
    const [ascending, setAscending] = useState(true);
    const toggleSort = (index) => {
        if (sortIndex === index) {
            setAscending(!ascending);
        }
        else {
            setAscending(true);
            setSortIndex(index);
        }
    };
    return { ascending, sortIndex, toggleSort };
}
