import { Container, ContainerGutter, CTA, Select, Text, } from "@volkswagen-onehub/components-core";
import { AllEnvironments } from "@onehub-manager/api";
import { Alert, ArrowRight } from "@volkswagen-onehub/icons-core";
import * as React from "react";
import styled from "styled-components";
import { getEnvName } from "../../utils/getEnvName";
const Redbox = styled.div `
  background: rgba(255, 51, 92, 0.1);
  padding: 20px;
  @media (min-width: 600px) {
    width: 600px;
  }
`;
const Headline = styled.h2 `
  color: black;
  margin: 0;
`;
const ButtonWrapper = styled.div `
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
const Copy = styled.div `
  padding: 1rem 0;
`;
export function RegisterVersionDangerZone(props) {
    const { currentVersions, releases, execute } = props;
    const [env, setEnv] = React.useState("stage");
    const [version, setVersion] = React.useState(releases.length > 0 ? releases[0].version : "");
    const isAnyRegistrationAllowed = () => {
        const { prod, int, stage } = props.registrationAllowed;
        return prod || int || stage;
    };
    const chosenReleases = releases.filter((r) => r.version === version);
    const release = chosenReleases.length === 1 ? chosenReleases[0] : undefined;
    const chosenIsCurrentVersion = currentVersions[env] === (release === null || release === void 0 ? void 0 : release.version);
    const allowedEnvironments = AllEnvironments.filter((env) => {
        return props.registrationAllowed[env];
    }).reduce((prev, curr) => {
        const value = { key: curr, name: getEnvName(curr) || "" };
        prev.push(value);
        return prev;
    }, []);
    return (React.createElement(React.Fragment, null, isAnyRegistrationAllowed() && (React.createElement(Redbox, null,
        React.createElement(Headline, null, "Danger Zone"),
        React.createElement(Copy, null,
            React.createElement(Container, { gutter: ContainerGutter.static200, wrap: "never", shrinkContent: true },
                React.createElement(Alert, { variant: "default" }),
                React.createElement(Text, null, "These changes don't follow the regular release process!"))),
        React.createElement(Container, { stretchContent: true },
            React.createElement(Container, { stretchContent: true, gutter: ContainerGutter.dynamic0120 },
                React.createElement(Select, { label: "Version", value: version, onChange: (event) => {
                        setVersion(event.currentTarget.value);
                    } }, releases.map((r) => (React.createElement("option", { key: r.version, value: r.version }, r.version)))),
                React.createElement(Select, { label: "Environment", value: env, onChange: (event) => {
                        setEnv(event.currentTarget.value);
                    } }, allowedEnvironments.map((e) => (React.createElement("option", { key: e.key, value: e.key }, e.name))))),
            React.createElement(ButtonWrapper, null,
                React.createElement(CTA, { disabled: !release || chosenIsCurrentVersion, tag: "button", icon: React.createElement(ArrowRight, null), emphasis: "primary", onClick: () => {
                        if (version)
                            execute(env, version);
                    } }, "Register")))))));
}
