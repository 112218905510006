import { useState } from "react";
export const useTableSettings = (meta) => {
    const [visibleColumns, setVisibleColumns] = useState(meta.columns.map((c) => !c.invisible));
    const [settingsOpen, setSettingsOpen] = useState(false);
    const updateVisibleColumn = (idx, newVisible) => {
        const newVisibleColumns = visibleColumns.map((visible, i) => {
            return i === idx ? newVisible : visible;
        });
        setVisibleColumns(newVisibleColumns);
    };
    return { visibleColumns, updateVisibleColumn, settingsOpen, setSettingsOpen };
};
