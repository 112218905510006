/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
export const useCustomFilters = (filters) => {
    const filterValueMap = filters.reduce((data, filter) => {
        data[filter.label] = filter.defaultValue;
        return data;
    }, {});
    const [data, setData] = React.useState(filterValueMap);
    return [
        (label) => data[label],
        (label, value) => setData(Object.assign(Object.assign({}, data), { [label]: value })),
    ];
};
