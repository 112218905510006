import * as React from "react";
import { Route } from "react-router";
import { BreadCrumbs } from "./Breadcrumbs";
import { Container, Layout, Breakpoints, styled, } from "@volkswagen-onehub/components-core";
const MarginContainer = styled.div `
  margin-bottom: 40px;
`;
export function View(props) {
    const { render, path, children } = props;
    return (React.createElement(Route, { path: path, exact: true }, (routeProps) => {
        if (!routeProps.match || !routeProps.match.isExact) {
            return null;
        }
        return (React.createElement(Layout, { allowOverflowingContent: true, appearance: {
                [Breakpoints.default]: [
                    {
                        name: ".",
                        columns: 2,
                    },
                    {
                        name: "a",
                        columns: 20,
                    },
                    {
                        name: ".",
                        columns: 2,
                    },
                    {
                        name: ".",
                        columns: 1,
                    },
                    {
                        name: "b",
                        columns: 22,
                    },
                    {
                        name: ".",
                        columns: 1,
                    },
                ],
            } },
            React.createElement(Container, { wrap: "always" },
                React.createElement(MarginContainer, null,
                    React.createElement(BreadCrumbs, { path: path }))),
            render
                ? render(routeProps.match ? routeProps.match.params : {})
                : children));
    }));
}
