import * as React from "react";
import { FocusLayer, ShimLayer, Container, ContainerGutter, ContainerPadding, CTA, LayerWrapper, Text, TokenTextAppearance, TextTag, FocusLayerSize, } from "@volkswagen-onehub/components-core";
import { StyledTable, StyledTd, StyledTh } from "../Table";
import styled, { css } from "styled-components";
export const StyledTr = styled.tr `
  ${(props) => props.noChanges &&
    css `
      color: gray;
      font-size: 12px;
    `};
`;
export const FeatureAppConfirmationLayer = ({ headline, handleCancel, handleConfirm, changes, }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(LayerWrapper, { contentCentered: true },
            React.createElement(ShimLayer, { key: "shim" }),
            React.createElement(FocusLayer, { key: "layer", active: true, size: FocusLayerSize.C },
                React.createElement(Container, { gutter: ContainerGutter.static400, horizontalAlign: "center", padding: ContainerPadding.static450, verticalAlign: "center", wrap: "always", stretchContent: true },
                    React.createElement(Container, { gutter: ContainerGutter.static300, wrap: "always" },
                        React.createElement(Text, { bold: true, appearance: TokenTextAppearance.headline300, tag: TextTag.h2 }, headline),
                        React.createElement(StyledTable, null,
                            React.createElement("thead", null,
                                React.createElement("tr", null,
                                    React.createElement(StyledTh, null),
                                    React.createElement(StyledTh, null),
                                    React.createElement(StyledTh, null, "before"),
                                    React.createElement(StyledTh, null, "after"))),
                            React.createElement("tbody", null, changes.map((c) => {
                                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
                                return (React.createElement(React.Fragment, { key: c.name },
                                    React.createElement(PropertyRow, { oldValue: (_a = c.oldVersion.version) !== null && _a !== void 0 ? _a : undefined, newValue: (_b = c.newVersion.version) !== null && _b !== void 0 ? _b : undefined, propertyName: "version", head: React.createElement(StyledTh, { wordBreak: true, rowSpan: 6 }, c.name) }),
                                    React.createElement(PropertyRow, { oldValue: (_c = c.oldVersion.baseUrl) !== null && _c !== void 0 ? _c : undefined, newValue: (_d = c.newVersion.baseUrl) !== null && _d !== void 0 ? _d : undefined, propertyName: "base url" }),
                                    React.createElement(PropertyRow, { oldValue: c.oldVersion.url, newValue: c.newVersion.url, propertyName: "client path" }),
                                    React.createElement(PropertyRow, { oldValue: (_e = c.oldVersion.ssrUrl) !== null && _e !== void 0 ? _e : undefined, newValue: (_f = c.newVersion.ssrUrl) !== null && _f !== void 0 ? _f : undefined, propertyName: "server path" }),
                                    React.createElement(PropertyRow, { oldValue: (_g = c.oldVersion.schema) !== null && _g !== void 0 ? _g : undefined, newValue: (_h = c.newVersion.schema) !== null && _h !== void 0 ? _h : undefined, propertyName: "schema" }),
                                    React.createElement(PropertyRow, { oldValue: (_j = c.oldVersion.team) !== null && _j !== void 0 ? _j : undefined, newValue: (_k = c.newVersion.team) !== null && _k !== void 0 ? _k : undefined, propertyName: "team" })));
                            }))),
                        React.createElement(Container, { stretchContent: true, gutter: ContainerGutter.dynamic0300 },
                            React.createElement(CTA, { onClick: handleCancel, tag: "button", emphasis: "secondary", stretchContent: true }, "cancel"),
                            React.createElement(CTA, { onClick: handleConfirm, tag: "button", emphasis: "primary", stretchContent: true }, "confirm"))))))));
};
function PropertyChangeCells(props) {
    const { oldValue, newValue } = props;
    if (!hasChanges(oldValue, newValue)) {
        return (React.createElement(React.Fragment, null,
            React.createElement(StyledTd, null, !isEmpty(oldValue) ? getDisplayValue(oldValue) : React.createElement("i", null, "(empty)")),
            React.createElement(StyledTd, null,
                React.createElement("i", null, "(no changes)"))));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledTd, null, !isEmpty(oldValue) ? getDisplayValue(oldValue) : React.createElement("i", null, "(empty)")),
        React.createElement(StyledTd, null, !isEmpty(newValue) ? getDisplayValue(newValue) : React.createElement("i", null, "(empty)"))));
}
function PropertyRow(props) {
    const { propertyName, oldValue, newValue, head } = props;
    return (React.createElement(StyledTr, { noChanges: !hasChanges(oldValue, newValue) },
        head,
        React.createElement(StyledTd, { wordBreak: true, noWrap: true }, propertyName),
        React.createElement(PropertyChangeCells, { oldValue: oldValue, newValue: newValue })));
}
function getDisplayValue(value) {
    return value && value.length > 100 ? React.createElement("i", null, "(too long)") : value;
}
function hasChanges(oldValue, newValue) {
    const oldEmpty = isEmpty(oldValue);
    const newEmpty = isEmpty(newValue);
    if (oldEmpty && newEmpty) {
        return false;
    }
    return oldValue !== newValue;
}
function isEmpty(oldValue) {
    return (oldValue === null || typeof oldValue === "undefined" || oldValue === "null");
}
