function versionToNumber(version) {
    if (typeof version !== "string") {
        return 0;
    }
    return version
        .split(".")
        .reverse()
        .map((v, idx) => parseInt(v) * Math.pow(1000, idx))
        .reduce((prev, curr) => prev + curr, 0);
}
export const sortVersion = (v1, v2) => {
    const i1 = versionToNumber(v1);
    const i2 = versionToNumber(v2);
    return i2 - i1;
};
