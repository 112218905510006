export const highlight = (text, filter) => {
    const textFragments = String(text)
        .toLowerCase()
        .split(String(filter).toLowerCase());
    let currentIndex = 0;
    return textFragments.map((tf, idx) => {
        const f = text
            ? text.substring(currentIndex, currentIndex + tf.length)
            : text;
        currentIndex += tf.length;
        const filterText = idx < textFragments.length - 1 && text
            ? text.substring(currentIndex, currentIndex + filter.length)
            : undefined;
        currentIndex += filter.length;
        return { text: f, filterText };
    });
};
