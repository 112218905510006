import * as React from "react";
import { Container, CTA, Select, styled, Text, TokenTextAppearance, } from "@volkswagen-onehub/components-core";
import { useQuery } from "@apollo/client";
import { usagesByNames } from "../queries/usages";
import { gql } from "../../__generated__";
const StyledUl = styled.ul `
  max-height: 60vh;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  overflow: scroll;
`;
const usagesQuery = gql(usagesByNames);
export const UsageTable = (props) => {
    const { appNames } = props;
    const { loading, data } = useQuery(usagesQuery, {
        variables: { appNames, env: "prod" },
    });
    const [domain, setDomain] = React.useState("");
    const [appName, setAppName] = React.useState("");
    if (loading || !data || !data.usages) {
        return null;
    }
    const usages = data.usages;
    const domains = new Set();
    usages.forEach((u) => {
        domains.add(u.domain);
    });
    const selectedDomain = domain;
    const marketUsages = usages
        .filter((u) => (selectedDomain === "" || u.domain === selectedDomain) &&
        (!appName || u.name === appName))
        .flatMap((u) => u.urls.map((url) => ({ title: new URL(url).pathname, href: url })))
        .sort((u1, u2) => u1.title.localeCompare(u2.title));
    return usages && usages.length ? (React.createElement(Container, { gutter: "dynamic0200", wrap: "always" },
        React.createElement(Text, { appearance: TokenTextAppearance.headline300 }, "Usages"),
        React.createElement(Container, { gutter: "dynamic0100", wrap: "never" },
            React.createElement(Select, { label: "domain", value: domain, onChange: (e) => {
                    setDomain(e.currentTarget.value);
                } },
                React.createElement("option", { key: "all", value: "" }, "All"),
                Array.from(domains).map((d) => (React.createElement("option", { key: d, value: d }, d)))),
            React.createElement(Select, { label: "Feature App", value: appName, onChange: (e) => {
                    setAppName(e.currentTarget.value);
                } },
                React.createElement("option", { key: "all", value: "" }, "All"),
                Array.from(appNames).map((d) => (React.createElement("option", { key: d, value: d }, d))))),
        React.createElement(StyledUl, { key: `${domain}-${appName}` }, marketUsages.map((u) => (React.createElement("li", { key: u.title + "_" + selectedDomain + "_" + appName },
            React.createElement(CTA, { emphasis: "tertiary", tag: "a", href: u.href, target: "_blank" }, u.title))))))) : null;
};
