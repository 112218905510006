import React from "react";
import { useQuery } from "@apollo/client";
import { gql } from "../../__generated__";
import { Loading } from "../component/Loading";
import { FlatServerQuery } from "../queries/Server";
import { Container, CTA } from "@volkswagen-onehub/components-core";
import { StyledTable, StyledTh, StyledTd } from "../component/Table";
import { Link } from "../component/Link";
const serversQuery = gql(FlatServerQuery);
export function ServerListView() {
    const { loading, error, data } = useQuery(serversQuery);
    return (React.createElement(Loading, { loading: loading, error: error, render: () => {
            return (React.createElement(Container, { gutter: "dynamic0200", wrap: "always" },
                React.createElement(StyledTable, null,
                    React.createElement("thead", null,
                        React.createElement(StyledTh, null, "Detail"),
                        React.createElement(StyledTh, null, "Markets"),
                        React.createElement(StyledTh, null, "Cms"),
                        React.createElement(StyledTh, null, "Feature Bundle Service"),
                        React.createElement(StyledTh, null, "Author")),
                    React.createElement("tbody", null, data === null || data === void 0 ? void 0 : data.servers.map((s) => {
                        var _a, _b;
                        return (React.createElement("tr", { key: s.name },
                            React.createElement(StyledTd, null,
                                React.createElement(Link, { path: `/server/${s.name}`, text: s.name })),
                            React.createElement(StyledTd, null,
                                React.createElement(Link, { path: `/server/${s.name}/market`, text: "markets" })),
                            React.createElement(StyledTd, null, (_a = s.cms) === null || _a === void 0 ? void 0 : _a.version),
                            React.createElement(StyledTd, null, (_b = s.serviceBundle) === null || _b === void 0 ? void 0 : _b.version),
                            React.createElement(StyledTd, null, s.defaultUrl && (React.createElement(CTA, { tag: "a", emphasis: "tertiary", href: s.defaultUrl }, "author")))));
                    })))));
        } }));
}
