import React from "react";
import { MarketsQueryByServer } from "../queries/Server";
import { CTA } from "@volkswagen-onehub/components-core";
import { ExtendedTable } from "../component/ExtendedTable";
import { gql } from "../../__generated__";
const query = gql(MarketsQueryByServer);
export function MarketListView(props) {
    const { envName } = props;
    const meta = {
        columns: [
            {
                sortable: true,
                header: { label: "Tenant" },
                field: (m) => {
                    return m.tenant;
                },
            },
            {
                sortable: true,
                header: { label: "Type" },
                field: (m) => {
                    return m.type === "cv" ? "Commercial" : "Private";
                },
            },
            {
                sortable: true,
                header: { label: "Country" },
                field: (m) => {
                    return `${m.country.name} (${m.country.code})`;
                },
            },
            {
                sortable: true,
                header: { label: "Region" },
                field: (m) => {
                    return `${m.country.region} (${m.country.subregion})`;
                },
            },
            {
                header: { label: "feature apps" },
                field: (s) => {
                    const text = envName === "prod" && s.featureAppUsages
                        ? /* eslint-disable-next-line @typescript-eslint/no-explicit-any*/
                            `${s.featureAppUsages.map((u) => u.name).join(", ")} (${s.featureAppUsages.length})`
                        : "";
                    return text;
                },
            },
            {
                header: { label: "Language" },
                groupKey: "sites",
                field: (s) => {
                    return `${s.language.name}`;
                },
                render: (s) => {
                    return (React.createElement(CTA, { emphasis: "tertiary", tag: "a", href: s.url }, s.language.name));
                },
            },
        ],
    };
    return (React.createElement(ExtendedTable, { meta: meta, query: query, variables: { envName }, 
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any*/
        getData: (data) => data.servers[0].markets }));
}
