import React, { Fragment } from "react";
import { highlight } from "./highlight";
export const HighlightFilter = (props) => {
    const { text, filter } = props;
    if (filter) {
        highlight(text, filter);
        return (React.createElement(React.Fragment, null, highlight(text, filter).map((t, idx) => (React.createElement(Fragment, { key: idx },
            t.text,
            t.filterText && React.createElement("b", null, t.filterText))))));
    }
    return React.createElement(React.Fragment, null, text);
};
