export const config = /* GraphQL */ `
  query config {
    config {
      aemAuthorApi {
        prod {
          baseUrl
          faRegistrationActivated
          faGroupName
          fsbGroupName
        }
        int {
          baseUrl
          faRegistrationActivated
          faGroupName
          fsbGroupName
        }
        stage {
          baseUrl
          faRegistrationActivated
          faGroupName
          fsbGroupName
        }
      }
      aemAuthorEnvs {
        prod
        int
        stage
      }
      aemPublishEnvs {
        prod
        int
        stage
      }
      appStore
      oscar
    }
  }
`;
