import React from "react";
import { allFeatureApps } from "../queries/FeatureApp";
import { CTA } from "@volkswagen-onehub/components-core";
import { topLevelColumns } from "./featureAppColumns";
import { ExtendedTable } from "../component/ExtendedTable";
import { gql } from "../../__generated__";
const meta = {
    columns: topLevelColumns.concat([
        {
            header: { label: "Server" },
            field: (d) => {
                return d.server.name;
            },
            groupKey: "servers",
        },
        {
            header: { label: "Version" },
            field: (d) => {
                return d.config.version;
            },
        },
        {
            header: { label: "Author Link" },
            field: (d) => d.config.path,
            render: (d) => {
                return (React.createElement(CTA, { target: "_blank", tag: "a", emphasis: "tertiary", href: `${d.server.authorUrl}/editor.html${d.config.path}.html` }, "edit"));
            },
        },
    ]),
};
const query = gql(allFeatureApps);
export function AllFeatureAppListView() {
    const filterFn = (appStore, data) => {
        const keep = !appStore || !!data.general.appStoreAppId;
        return keep ? data : undefined;
    };
    const envFilterFn = (selectedEnv, data) => {
        if (!selectedEnv)
            return data;
        if (!data.configurations) {
            return data;
        }
        const envConfigurations = data.configurations
            .map((c) => {
            var _a;
            const newServers = (_a = c.servers) === null || _a === void 0 ? void 0 : _a.filter((s) => s.server.name === selectedEnv);
            if ((newServers === null || newServers === void 0 ? void 0 : newServers.length) == 0) {
                return undefined;
            }
            return Object.assign(Object.assign({}, c), { servers: newServers });
        })
            .filter((c) => !!c);
        if (envConfigurations.length === 0) {
            return undefined;
        }
        return Object.assign(Object.assign({}, data), { configurations: envConfigurations });
    };
    return (React.createElement(ExtendedTable, { query: query, meta: meta, getData: (data) => data.allFeatureApps, autoFetchMore: { limit: 50 }, filters: [
            {
                label: "App store",
                filterFn,
                defaultValue: true,
                type: "boolean",
            },
            {
                label: "Environment",
                filterFn: envFilterFn,
                defaultValue: "",
                type: "select",
                options: [
                    { label: "All", value: "" },
                    { label: "Prod", value: "prod" },
                    { label: "Int", value: "int" },
                    { label: "Stage", value: "stage" },
                ],
            },
        ] }));
}
