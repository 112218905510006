import React, { useContext } from "react";
import { ServerListView } from "./container/ServerListView";
import { ApolloProvider } from "@apollo/client";
import { BrowserRouter as Router } from "react-router-dom";
import { AllFeatureAppListView } from "./container/AllFeatureAppListView";
import { ServerView } from "./container/ServerView";
import { FeatureAppView } from "./container/FeatureAppView";
import { AllServiceBundleView } from "./container/AllServiceBundleView";
import { ApplicationContainer } from "./ApplicationContainer";
import { DashboardView } from "./container/DashboardView";
import { ServiceContext, services } from "./context/ServiceContext";
import { View } from "./component/View";
import { MarketListView } from "./container/MarketListView";
import { DeploymentSetView } from "./container/DeploymentSetView";
import { ConfigContext } from "./context/ConfigContext";
export function App() {
    const { client } = useContext(ConfigContext);
    if (!client) {
        throw new Error("no config and no client");
    }
    return (React.createElement(ApolloProvider, { client: client },
        React.createElement(ServiceContext.Provider, { value: services },
            React.createElement(ApplicationContainer, null,
                React.createElement("div", null,
                    React.createElement(Router, null,
                        React.createElement(View, { path: "/" },
                            React.createElement(DashboardView, null)),
                        React.createElement(View, { path: "/server" },
                            React.createElement(ServerListView, null)),
                        React.createElement(View, { path: "/server/:serverName", render: (params) => {
                                const envName = params.serverName;
                                return React.createElement(ServerView, { envName: envName });
                            } }),
                        React.createElement(View, { path: "/featureapp" },
                            React.createElement(AllFeatureAppListView, null)),
                        React.createElement(View, { path: "/serviceBundle" },
                            React.createElement(AllServiceBundleView, null)),
                        React.createElement(View, { path: "/featureapp/:appName", render: (params) => {
                                const appName = params.appName;
                                return React.createElement(FeatureAppView, { appName: appName });
                            } }),
                        React.createElement(View, { path: "/deploymentset/:deploymentSet", render: (params) => {
                                const deploymentSet = params.deploymentSet;
                                return (React.createElement(DeploymentSetView, { deploymentSetName: deploymentSet }));
                            } }),
                        React.createElement(View, { path: "/server/:serverName/market", render: (params) => {
                                const envName = params.serverName;
                                return React.createElement(MarketListView, { envName: envName });
                            } })))))));
}
