import { filterProperReleases } from "../container/filterProperReleases";
import { sortVersion } from "./sortVersion";
function updateVersion(currentVersion, version) {
    var _a, _b, _c;
    currentVersion.entries.push(version);
    version.featureApps &&
        (currentVersion.featureApps = updateArray(currentVersion.featureApps, version.featureApps, (f1, f2) => f1.defaultId === f2.defaultId));
    currentVersion.pageIds = updateArray(currentVersion.pageIds, (_a = version.pageIds) !== null && _a !== void 0 ? _a : undefined, (f1, f2) => f1.defaultId === f2.defaultId);
    currentVersion.serviceConfigurations = updateArray(currentVersion.serviceConfigurations, (_b = version.serviceConfigurations) !== null && _b !== void 0 ? _b : undefined, (f1, f2) => f1.id === f2.id);
    currentVersion.featureServices = updateArray(currentVersion.featureServices, (_c = version.featureServices) !== null && _c !== void 0 ? _c : undefined, (f1, f2) => f1.name === f2.name);
}
function createVersion(appVersion) {
    var _a, _b;
    return {
        entries: [appVersion],
        featureApps: appVersion.featureApps || [],
        pageIds: appVersion.pageIds || [],
        featureServices: appVersion.featureServices || [],
        serviceConfigurations: appVersion.serviceConfigurations || [],
        version: (_a = appVersion.version) !== null && _a !== void 0 ? _a : undefined,
        libs: (_b = appVersion.libs) !== null && _b !== void 0 ? _b : undefined,
    };
}
function updateVersions(versions, appVersions) {
    appVersions.forEach((appVersion) => {
        const candidates = versions.filter((v) => v.version === appVersion.version);
        if (candidates.length > 0) {
            updateVersion(candidates[0], appVersion);
        }
        else {
            versions.push(createVersion(appVersion));
        }
    });
}
export function mergeFeatureAppsOfDeploymentSet(apps) {
    const deploymentSet = apps[0].general.deploymentSet;
    if (!deploymentSet) {
        return undefined;
    }
    const deployments = apps[0].deployments;
    const featureAppsOfDeploymentSet = apps.reduce((prevSet, currSet) => {
        var _a, _b, _c, _d, _e, _f;
        (_a = currSet.configurations) === null || _a === void 0 ? void 0 : _a.forEach((c) => prevSet.configurations.push(c));
        prevSet.featureApps.push(currSet.general);
        prevSet.teams.add((_b = currSet.general.team) !== null && _b !== void 0 ? _b : undefined);
        prevSet.repositoryUrls.add((_c = currSet.general.repositoryUrl) !== null && _c !== void 0 ? _c : undefined);
        prevSet.releasePageUrls.add((_e = (_d = currSet.latestVersion) === null || _d === void 0 ? void 0 : _d.releasePageUrl) !== null && _e !== void 0 ? _e : undefined);
        if (!prevSet.latestVersion) {
            const latestVersion = createVersion(currSet.latestVersion);
            prevSet.latestVersion = latestVersion;
        }
        else {
            const latestVersion = prevSet.latestVersion;
            updateVersion(latestVersion, currSet.latestVersion);
        }
        updateVersions(prevSet.versions, (_f = currSet.versions) !== null && _f !== void 0 ? _f : []);
        return prevSet;
    }, {
        deploymentSet,
        teams: new Set(),
        repositoryUrls: new Set(),
        releasePageUrls: new Set(),
        featureApps: [],
        configurations: [],
        versions: [],
    });
    featureAppsOfDeploymentSet.versions = filterProperReleases(featureAppsOfDeploymentSet.versions);
    featureAppsOfDeploymentSet.versions.sort((v1, v2) => v1.version && v2.version ? sortVersion(v1.version, v2.version) : 0);
    deployments && (featureAppsOfDeploymentSet.deployments = deployments);
    return featureAppsOfDeploymentSet;
}
function updateArray(currentArray, newArray, equals) {
    if (newArray) {
        const updateArray = [...currentArray];
        newArray
            .filter((t) => currentArray.filter((existing) => equals(t, existing)).length == 0)
            .forEach((t) => {
            updateArray.push(t);
        });
    }
    return currentArray;
}
