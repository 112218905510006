export function formatDateTime(dateString) {
    try {
        const date = new Date(dateString);
        return new Intl.DateTimeFormat(navigator.language, {
            dateStyle: "short",
            timeStyle: "short",
        }).format(date);
    }
    catch (e) {
        return dateString;
    }
}
