import React from "react";
import styled from "styled-components";
import { Container, ContainerGutter, ContainerVerticalAlignment, CTA, Text, } from "@volkswagen-onehub/components-core";
import { ArrowRight } from "@volkswagen-onehub/icons-core";
import { StyledTd } from "../Table";
import { scrollToRelease } from "../scrollToRelease";
import { getVersionAnchor } from "../getVersionAnchor";
import { formatDateTime } from "../formatDateTime";
const StyledWrapper = styled.div `
  height: 2.5rem;
  display: flex;
  align-items: center;
`;
const VersionLink = (props) => {
    return (React.createElement(CTA, { tag: "a", emphasis: "tertiary", href: `#${getVersionAnchor(props.version)}`, onClick: scrollToRelease(props.version) }, props.version));
};
export function RegisterVersionRow(props) {
    const { name, envId, currentVersion, newVersion, registrationAllowed, execute, lastDeploymentDate, } = props;
    let registrationEnabled;
    let message;
    if (!newVersion.version) {
        registrationEnabled = false;
        message = "";
    }
    else if (currentVersion && newVersion.version.includes(currentVersion)) {
        registrationEnabled = false;
        message = (React.createElement(Text, null,
            "Current version on ",
            name,
            " is up to date with",
            " ",
            React.createElement(VersionLink, { version: newVersion.version }),
            " (",
            newVersion.name,
            ")"));
    }
    else if (registrationAllowed) {
        registrationEnabled = true;
        message = (React.createElement(Text, null,
            "Register ",
            React.createElement(VersionLink, { version: newVersion.version }),
            " (",
            newVersion.name,
            ")"));
    }
    else {
        registrationEnabled = false;
        message = (React.createElement(Text, null,
            "A new version ",
            React.createElement(VersionLink, { version: newVersion.version }),
            " (",
            newVersion.name,
            ") is available"));
    }
    return (React.createElement("tr", null,
        React.createElement(StyledTd, { layout: "CATEGORY" },
            React.createElement(Text, { bold: true }, name)),
        React.createElement(StyledTd, null,
            React.createElement(Text, null,
                React.createElement(VersionLink, { version: currentVersion || "" }))),
        React.createElement(StyledTd, null,
            React.createElement(Text, null, formatDateTime(lastDeploymentDate || ""))),
        React.createElement(StyledTd, null,
            React.createElement(StyledWrapper, null,
                React.createElement(Container, { verticalAlign: ContainerVerticalAlignment.center, gutter: ContainerGutter.dynamic0040 },
                    React.createElement(Text, null, message),
                    registrationEnabled && (React.createElement(CTA, { tag: "button", icon: React.createElement(ArrowRight, null), emphasis: "primary", onClick: () => {
                            if (newVersion.version)
                                execute(envId, newVersion.version);
                        } }, "Register")))))));
}
