import React from "react";
import { useQuery } from "@apollo/client";
import { gql } from "../../__generated__";
import { Link } from "../component/Link";
import { Loading } from "../component/Loading";
import { DetailServerQuery } from "../queries/Server";
import { Text, Container, CTA, TokenTextAppearance, } from "@volkswagen-onehub/components-core";
import { StyledTable, StyledTd, StyledTh } from "../component/Table";
const serversQuery = gql(DetailServerQuery);
export function ServerView(props) {
    const { envName } = props;
    const { loading, error, data } = useQuery(serversQuery, {
        variables: { envName },
    });
    return (React.createElement(Loading, { loading: loading, error: error, render: () => {
            var _a, _b;
            const server = data === null || data === void 0 ? void 0 : data.servers[0];
            return (React.createElement(Container, { gutter: "dynamic0200", wrap: "always" },
                React.createElement(Text, { appearance: TokenTextAppearance.headline300 }, envName),
                React.createElement(StyledTable, null,
                    React.createElement("tbody", null,
                        React.createElement("tr", null,
                            React.createElement(StyledTh, { layout: "CATEGORY" }, "Name"),
                            React.createElement(StyledTd, null, server === null || server === void 0 ? void 0 : server.name)),
                        React.createElement("tr", null,
                            React.createElement(StyledTh, { layout: "CATEGORY" }, "Cms Version"),
                            React.createElement(StyledTd, null, (_a = server === null || server === void 0 ? void 0 : server.cms) === null || _a === void 0 ? void 0 : _a.version)),
                        React.createElement("tr", null,
                            React.createElement(StyledTh, { layout: "CATEGORY" }, "Feature Service Bundle"),
                            React.createElement(StyledTd, null, (_b = server === null || server === void 0 ? void 0 : server.serviceBundle) === null || _b === void 0 ? void 0 : _b.version)),
                        React.createElement("tr", null,
                            React.createElement(StyledTh, { layout: "CATEGORY" }),
                            React.createElement(StyledTd, null,
                                React.createElement(CTA, { tag: "a", emphasis: "tertiary", href: "{s.defaultUrl}" }, "author"))),
                        React.createElement("tr", null,
                            React.createElement(StyledTh, { layout: "CATEGORY" }),
                            React.createElement(StyledTd, null,
                                React.createElement(Link, { path: `/server/${server === null || server === void 0 ? void 0 : server.name}/market`, text: "markets" })))))));
        } }));
}
