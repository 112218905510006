export function getLastDeploymentDate(env, expectedVersion, deployments) {
    var _a;
    if (!deployments) {
        return undefined;
    }
    const serverDeployment = deployments.find((d) => d.env === env);
    if (serverDeployment &&
        serverDeployment.deployment &&
        serverDeployment.deployment.version === expectedVersion) {
        return (_a = serverDeployment.deployment.depl_time) !== null && _a !== void 0 ? _a : undefined;
    }
    return undefined;
}
