import React, { useEffect, useState } from "react";
import { Hub } from "aws-amplify/utils";
import { getCurrentUser, signInWithRedirect, } from "@aws-amplify/auth";
import { App } from "./App";
export function AppWithAuth() {
    const [user, setUser] = useState(null);
    useEffect(() => {
        Hub.listen("auth", ({ payload: { event } }) => {
            switch (event) {
                case "signInWithRedirect":
                    getCurrentUser().then((userData) => setUser(userData));
                    break;
                case "signedOut":
                    setUser(null);
                    break;
                case "signInWithRedirect_failure":
                    console.log("Sign in failure");
                    break;
            }
        });
        getCurrentUser()
            .then((userData) => setUser(userData))
            .catch(() => signInWithRedirect());
    }, []);
    return React.createElement(React.Fragment, null, user && React.createElement(App, null));
}
