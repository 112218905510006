export function filter(data, filtering) {
    if (!data) {
        return false;
    }
    const directFieldMatches = filtering.filters.filter((f) => data[f.field] === f.value);
    if (directFieldMatches.length === filtering.filters.length) {
        if (filtering.value) {
            const otherMatches = filtering.fields.filter((f) => {
                const values = f(data, filtering.value);
                const matches = values.filter((v) => !!v &&
                    String(v).toLowerCase().includes(filtering.value.toLowerCase()));
                return matches.length > 0;
            });
            return otherMatches.length > 0;
        }
        else {
            return true;
        }
    }
    return false;
}
