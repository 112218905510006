import * as React from "react";
import styled from "styled-components";
import { Breakpoints, Text, TokenTextAppearance, TextTag, } from "@volkswagen-onehub/components-core";
const StyledBreadcrumbsContainer = styled.ol `
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  padding: 0;
  margin: 0;
  list-style: none;

  @media (max-width: ${Breakpoints.b560}px) {
    display: none;
  }
`;
StyledBreadcrumbsContainer.displayName = "StyledBreadcrumbsContainer";
const StyledSeparator = styled.div `
  padding: 0 ${(props) => props.theme.size.static150};
  & > svg {
    height: 12px;
    width: 12px;
    path {
      fill: ${(props) => props.theme.interaction.recipe450.default};
    }
  }
`;
StyledSeparator.displayName = "StyledSeparator";
// Color has to be defined also here otherwise ellipsis color is wrong.
const StyledBreadcrumbItem = styled.li `
  display: ${(props) => (props.isHidden ? "none" : "flex")};
  flex-grow: 0;
  align-items: center;
  white-space: nowrap;
  color: ${(props) => props.theme.interaction.recipe450.default};
  overflow: hidden;

  &:first-child {
    overflow: visible;
  }

  &:last-child > ${StyledSeparator} {
    display: none;
  }
`;
StyledBreadcrumbItem.displayName = "StyledBreadcrumbItem";
const StyledCollapsedBreadcrumbItem = styled(StyledBreadcrumbItem) `
  overflow: visible;
`;
StyledBreadcrumbItem.displayName = "StyledCollapsedBreadcrumbItem";
const StyledItemWrapper = styled.div `
  overflow: hidden;
  text-overflow: ellipsis;
`;
StyledItemWrapper.displayName = "StyledItemWrapper";
// According to D6 principles this is not correct, unfortunately design requires
// this color for last item also. In future iterations this should be removed and
// design should be reworked.
export const StyledColoredText = styled.span `
  color: ${(props) => props.theme.interaction.recipe450.default};
`;
StyledColoredText.displayName = "StyledColoredText";
const Separator = () => (React.createElement(StyledSeparator, null,
    React.createElement("svg", { viewBox: "0 0 12 12" },
        React.createElement("path", { d: "M2,2.34,2.88,1,9.23,4.63l.08,0a1.83,1.83,0,0,1,.32.3,1.59,1.59,0,0,1-.32,2.33l-.08.05L2.88,11,2,9.66,8.3,6.05a.08.08,0,0,0,0-.11Z" }))));
Separator.displayName = "Separator";
const BreadcrumbItem = (props) => (React.createElement(StyledBreadcrumbItem, { isHidden: props.isHidden, itemProp: "itemListElement", itemScope: true, itemType: "http://schema.org/ListItem" },
    React.createElement(StyledItemWrapper, null, props.children),
    React.createElement(Separator, null)));
BreadcrumbItem.displayName = "BreadcrumbItem";
const CollapsedItems = () => (React.createElement(StyledCollapsedBreadcrumbItem, null,
    React.createElement(Text, { tag: TextTag.span, appearance: TokenTextAppearance.copy200 },
        React.createElement(StyledColoredText, null, "\u2026")),
    React.createElement(Separator, null)));
CollapsedItems.displayName = "CollapsedItems";
// If there are more than 3 breadcrumb items we should only render top level element
// and last two. Last item should be cut with ellipsis if it overflows parent container.
export const Breadcrumbs = (props) => {
    const breadcrumbItems = React.Children.toArray(props.children).filter(React.isValidElement);
    return (React.createElement(StyledBreadcrumbsContainer, { title: props.title, itemScope: true, itemType: "http://schema.org/BreadcrumbList" }, React.Children.map(breadcrumbItems, (item, idx) => (React.createElement(React.Fragment, null,
        breadcrumbItems.length > 3 && idx === 1 && React.createElement(CollapsedItems, null),
        React.createElement(BreadcrumbItem, { isHidden: breadcrumbItems.length > 3 &&
                idx > 0 &&
                idx < breadcrumbItems.length - 2 }, item))))));
};
