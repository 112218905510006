function mapGroup(data, groupKeys, allColumns, visibleColumns, parent, filter) {
    const groupKey = groupKeys[0];
    const columns = allColumns
        .filter((_c, idx) => visibleColumns[idx])
        .filter((c) => (!groupKey && !c.groupKey) || c.groupKey === groupKey);
    const groupData = groupKey ? data[groupKey] : data;
    let span = groupData.length === 0 ? 1 : 0;
    const rows = [];
    groupData.forEach((dataValue) => {
        const rowData = [];
        columns.forEach((c) => {
            rowData.push(c.render(dataValue, parent, filter));
        });
        if (groupKeys.length > 1) {
            const newParent = { value: dataValue, parent };
            const result = mapGroup(dataValue, groupKeys.slice(1), allColumns, visibleColumns, newParent, filter);
            span += result.span;
            const firstRow = rowData
                .map((d) => ({ span: result.span, data: d }))
                .concat(result.rows[0].cells);
            rows.push({ cells: firstRow });
            result.rows.slice(1).forEach((r) => rows.push(r));
        }
        else {
            span++;
            const cells = rowData.map((d) => ({ span: 1, data: d }));
            rows.push({ cells });
        }
    });
    return { span, rows };
}
export const map = (data, allColumns, visibleColumns, filter) => {
    const groupsWithDuplicates = allColumns
        .filter((c) => c.groupKey)
        .map((c) => c.groupKey);
    const groups = [""];
    groupsWithDuplicates.forEach((d) => {
        if (d && groups.indexOf(d) < 0) {
            groups.push(d);
        }
    });
    const result = mapGroup(data, groups, allColumns, visibleColumns, undefined, filter);
    return result.rows;
};
