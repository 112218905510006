export const dashboard = /* GraphQL */ `
  query dashboard {
    featureAppDashboard: featureAppDashboard {
      prod {
        featureApp
        deploymentSet
      }
    }
    markets: servers(envName: "prod") {
      markets {
        domain
      }
    }
    bundle: allServiceBundle {
      servers {
        name
        serviceBundle {
          version
        }
      }
    }
  }
`;
