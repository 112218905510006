import { AlertLayer, Container, ContainerGutter, CTA, LayerWrapper, ShimLayer, Spinner, styled, Text, TokenTextAppearance, TextTag, } from "@volkswagen-onehub/components-core";
import * as React from "react";
const ErrorWrapper = styled.div `
  padding: 45px;
`;
export class LayerManager {
    constructor() {
        this.layer = null;
    }
    openAlert(layer) {
        this.layerType = "alert";
        this.layer = layer;
        this.notify();
    }
    closeAlert() {
        if (this.layer && this.layerType === "alert") {
            this.layer = null;
            this.notify();
        }
    }
    openError(headline, message) {
        this.layerType = "error";
        this.layer = this.createErrorLayer(headline, message);
        this.notify();
    }
    closeError() {
        if (this.layer && this.layerType === "error") {
            this.layer = null;
            this.notify();
        }
    }
    openSpinner() {
        this.layerType = "spinner";
        this.layer = this.createProgressLayer();
        this.notify();
    }
    closeSpinner() {
        if (this.layer && this.layerType === "spinner") {
            this.layer = null;
            this.notify();
        }
    }
    subscribe(cb) {
        this.cb = cb;
    }
    createErrorLayer(headline, message) {
        const close = () => this.closeError();
        return (React.createElement(LayerWrapper, { contentCentered: true },
            React.createElement(ShimLayer, { key: "shim" }),
            React.createElement(AlertLayer, { active: true },
                React.createElement(ErrorWrapper, null,
                    React.createElement(Container, { gutter: ContainerGutter.static300, wrap: "always" },
                        React.createElement(Text, { bold: true, appearance: TokenTextAppearance.headline400, tag: TextTag.h3 }, headline),
                        React.createElement(Text, { appearance: TokenTextAppearance.copy200, tag: TextTag.p }, message),
                        React.createElement("br", null),
                        React.createElement(CTA, { onClick: () => close(), tag: "button", emphasis: "primary", stretchContent: true }, "Ok"))))));
    }
    createProgressLayer() {
        return (React.createElement(LayerWrapper, { contentCentered: true },
            React.createElement(ShimLayer, { key: "shim" }),
            React.createElement(AlertLayer, { active: true },
                React.createElement(ErrorWrapper, null,
                    React.createElement("div", { style: { textAlign: "center" } },
                        React.createElement(Spinner, { variant: "x-large" }),
                        React.createElement(Text, null, "Waiting for new version to be visible on dispatcher"))))));
    }
    notify() {
        console.log("changing layer", !!this.layer, this.layerType);
        if (this.cb) {
            this.cb();
        }
    }
}
