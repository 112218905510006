import React, { useContext, useState } from "react";
import { useQuery } from "@apollo/client";
import { gql } from "../../__generated__";
import { Loading } from "../component/Loading";
import { AllServiceBundle, RegisterFeatureAppConfigurations, RegisterFeatureServiceBundle, } from "../queries/ServiceBundle";
import { Text, Container, TokenTextAppearance, } from "@volkswagen-onehub/components-core";
import { StyledTable } from "../component/Table";
import { ReleasesView } from "./ReleasesView";
import { RegisterVersionRow } from "../component/register/RegisterVersionRow";
import { RegisterVersionDangerZone } from "../component/register/RegisterVersionDangerZone";
import { reloadFactory, } from "../component/register/reloadFactory";
import { registerServiceBundleAction } from "../component/register/registerServiceBundleAction";
import { ServiceContext } from "../context/ServiceContext";
function getVersionFromRelease(releases) {
    return releases[0].version;
}
function getDataByServerName(data, name) {
    var _a, _b, _c;
    let version;
    let url;
    const sd = (_a = data.allServiceBundle.deployments) === null || _a === void 0 ? void 0 : _a.find((serverDeployment) => {
        return serverDeployment && serverDeployment.env === name;
    });
    (_b = data.allServiceBundle.servers) === null || _b === void 0 ? void 0 : _b.forEach((server) => {
        if (server.name === name && server.serviceBundle) {
            version = server.serviceBundle.version;
            url = server.serviceBundle.url;
        }
    });
    return {
        version,
        envId: name,
        url,
        deployment: (_c = sd === null || sd === void 0 ? void 0 : sd.deployment) !== null && _c !== void 0 ? _c : undefined,
    };
}
const allServiceBundleQuery = gql(AllServiceBundle);
export const registerServiceBundleMutation = gql(RegisterFeatureServiceBundle);
export const registerFeatureAppConfigurations = gql(RegisterFeatureAppConfigurations);
export function AllServiceBundleView() {
    const [update, setUpdate] = useState(false);
    const { loading, error, data, refetch } = useQuery(allServiceBundleQuery);
    const layerManager = useContext(ServiceContext).layerManager;
    const reload = reloadFactory(refetch, setUpdate, (timeout) => {
        layerManager.openError("Failed to Register", `Newly registered service bundle was not available on the publisher after ${timeout} seconds. Try again later.`);
    });
    const reloadExpected = (envId, version) => {
        reload((data) => {
            const serviceBundle = getDataByServerName(data, envId);
            return serviceBundle.version === version;
        });
    };
    return (React.createElement(Loading, { loading: loading, reloadMessage: "loading update ...", reloading: update, error: error, render: () => {
            var _a, _b, _c, _d, _e, _f, _g;
            if (!data)
                return null;
            if (!data.getUserGroup) {
                // get UserGroup is sometimes very slow (>45s)
                setTimeout(() => refetch(), 500);
            }
            const registrationAllowed = data.getUserGroup.featureAppAdmin;
            const releases = (_a = data.allServiceBundle.releases) === null || _a === void 0 ? void 0 : _a.map((r) => (Object.assign(Object.assign({}, r), { anchor: `release_id${(r.version || "").split(".").join("_")}` })));
            if (!releases)
                return null;
            const releaseMap = {};
            releases.forEach((r) => (releaseMap[r.version] = r));
            const production = Object.assign({ name: "Production" }, getDataByServerName(data, "prod"));
            const integration = Object.assign({ name: "Integration" }, getDataByServerName(data, "int"));
            const stage = Object.assign({ name: "Stage" }, getDataByServerName(data, "stage"));
            const latestRelease = releases.length > 0
                ? {
                    version: getVersionFromRelease(releases),
                    url: releases[0].url,
                }
                : undefined;
            const stageVersion = {
                name: "latest version",
                version: latestRelease ? latestRelease.version : "",
                url: latestRelease ? latestRelease.url : "",
            };
            const intVersion = {
                name: "stage version",
                version: stage.version || "",
                url: stage.url || "",
            };
            const prodVersion = {
                name: "stage version",
                version: stage.version || "",
                url: stage.url || "",
            };
            const serviceBundleReleases = releases.map((r) => ({
                name: r.version,
                version: r.version,
                url: r.url,
            }));
            const execute = registerServiceBundleAction(layerManager, serviceBundleReleases, reloadExpected);
            return (React.createElement(Container, { gutter: "dynamic0200", wrap: "always" },
                React.createElement(Text, { appearance: TokenTextAppearance.headline300 }, "Feature Service Bundle"),
                React.createElement(StyledTable, null,
                    React.createElement("tbody", null,
                        React.createElement(RegisterVersionRow, { execute: execute, name: production.name, currentVersion: production.version, envId: production.envId, newVersion: prodVersion, registrationAllowed: registrationAllowed.prod, lastDeploymentDate: (_c = (_b = production.deployment) === null || _b === void 0 ? void 0 : _b.depl_time) !== null && _c !== void 0 ? _c : undefined }),
                        React.createElement(RegisterVersionRow, { execute: execute, name: integration.name, currentVersion: integration.version, envId: integration.envId, newVersion: intVersion, registrationAllowed: registrationAllowed.int, lastDeploymentDate: (_e = (_d = integration.deployment) === null || _d === void 0 ? void 0 : _d.depl_time) !== null && _e !== void 0 ? _e : undefined }),
                        React.createElement(RegisterVersionRow, { execute: execute, name: stage.name, currentVersion: stage.version, envId: stage.envId, newVersion: stageVersion, registrationAllowed: registrationAllowed.stage, lastDeploymentDate: (_g = (_f = stage.deployment) === null || _f === void 0 ? void 0 : _f.depl_time) !== null && _g !== void 0 ? _g : undefined }))),
                React.createElement(RegisterVersionDangerZone, { execute: execute, releases: serviceBundleReleases, registrationAllowed: registrationAllowed, currentVersions: {
                        prod: production.version,
                        int: integration.version,
                        stage: stage.version,
                    } }),
                React.createElement(Text, { appearance: TokenTextAppearance.headline300 }, "Available Versions"),
                React.createElement(ReleasesView, { releases: releases })));
        } }));
}
