export const NotifyOscar = /* GraphQL */ `
  mutation NotifyOscar(
    $envName: String!
    $name: String!
    $version: String!
    $category: String!
  ) {
    notifyOscar(
      env: $envName
      name: $name
      version: $version
      category: $category
    ) {
      success
      msg
    }
  }
`;
export const NotifyOscarAndJira = /* GraphQL */ `
  mutation NotifyOscarAndJira(
    $envName: String!
    $name: String!
    $version: String!
    $category: String!
    $ticketId: String
    $transitionId: String
    $comment: String
  ) {
    notifyOscar(
      env: $envName
      name: $name
      version: $version
      category: $category
    ) {
      success
      msg
    }
    handleJiraWorkflow(
      ticketId: $ticketId
      transitionId: $transitionId
      comment: $comment
    ) {
      success
      msg
    }
  }
`;
