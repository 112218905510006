import * as React from "react";
import { Link } from "../component/Link";
/* eslint-disable @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any */
const getConfig = (d) => {
    if (d.config) {
        return d.config;
    }
    return d;
};
export const configurationColumns = [
    {
        invisible: true,
        header: { label: "Version" },
        field: (d) => {
            return getConfig(d).version;
        },
    },
];
export const topLevelColumns = [
    {
        header: { label: "Title" },
        sortable: true,
        field: (f) => f.general.title || f.general.name,
        render: (f) => {
            const name = f.general.title || f.general.name;
            return (React.createElement(Link, { path: `/featureapp/${encodeURIComponent(f.general.name)}`, text: name }));
        },
    },
    {
        header: { label: "Deployment Set" },
        sortable: true,
        field: (f) => f.general.deploymentSet,
        render: (f) => {
            const deploymentSet = f.general.deploymentSet;
            if (deploymentSet) {
                const name = typeof deploymentSet === "string"
                    ? deploymentSet
                    : deploymentSet.name;
                return (React.createElement(Link, { path: `/deploymentSet/${encodeURIComponent(name)}`, text: name }));
            }
            else {
                return null;
            }
        },
    },
    {
        header: { label: "Latest Version" },
        sortable: false,
        field: (f) => {
            var _a;
            return (_a = f.latestVersion) === null || _a === void 0 ? void 0 : _a.version;
        },
    },
    {
        header: { label: "App Store Id" },
        invisible: true,
        sortable: true,
        field: (f) => {
            return f.general.appStoreAppId;
        },
    },
    {
        header: { label: "Team" },
        sortable: true,
        invisible: true,
        field: (f) => {
            return f.general.team;
        },
    },
    {
        header: { label: "Configuration" },
        field: "name",
        groupKey: "configurations",
    },
];
