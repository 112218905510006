import * as React from "react";
import styled from "styled-components";
import { Breakpoints, CTA, Text, TokenTextAppearance, TextTag, } from "@volkswagen-onehub/components-core";
const StyledCustomContainer = styled.div `
  display: flex;
  @media (max-width: ${Breakpoints.b560}px) {
    flex-direction: column;
  }
`;
StyledCustomContainer.displayName = "ArticleMetaInfo.StyledCustomContainer";
const StyledCustomChildWrapper = styled.div `
  @media (min-width: ${Breakpoints.b560}px) {
    padding-left: ${(props) => props.theme.size.static200};
    margin-left: ${(props) => props.theme.size.static200};
    border-left: 1px solid ${(props) => props.theme.text.color.primary};
  }
  @media (max-width: ${Breakpoints.b560}px) {
    margin-top: ${(props) => props.theme.size.static150};
  }
`;
StyledCustomChildWrapper.displayName =
    "ArticleMetaInfo.StyledCustomChildWrapper";
export const ArticleMetaInfo = (props) => {
    const childElements = React.Children.toArray(props.children).filter(React.isValidElement);
    return (React.createElement(StyledCustomContainer, null, React.Children.map(childElements, (child, index) => index > 0 ? (React.createElement(StyledCustomChildWrapper, null, child)) : (React.createElement("div", null, child)))));
};
export const TextBlockWithLabel = ({ label, text, href, onClick }) => (React.createElement(Text, { tag: TextTag.span, appearance: TokenTextAppearance.label150 },
    React.createElement(Text, { tag: TextTag.span }, label),
    !href && (React.createElement(Text, { tag: TextTag.span, bold: true },
        "\u00A0",
        text)),
    (href || onClick) && (React.createElement(CTA, { tag: "a", emphasis: "tertiary", href: href, target: "_blank", onClick: onClick },
        React.createElement(Text, { tag: TextTag.span, bold: true },
            "\u00A0",
            text)))));
