export const FlatServerQuery = /* GraphQL */ `
  query FlatServerQuery($envName: String) {
    servers(envName: $envName) {
      name
      defaultMarketUrl
      defaultUrl
      authorUrl
      serviceBundle {
        url
        version
      }
      cms {
        version
      }
    }
  }
`;
export const DetailServerQuery = /* GraphQL */ `
  query DetailServerQuery($envName: String) {
    servers(envName: $envName) {
      name
      defaultMarketUrl
      defaultUrl
      authorUrl
      serviceBundle {
        url
        version
      }
      cms {
        version
      }
    }
  }
`;
export const MarketsQueryByServer = /* GraphQL */ `
  query MarketsQueryByServer($envName: String) {
    servers(envName: $envName) {
      markets {
        tenant
        countryCode
        country {
          code
          name
          region
          subregion
        }
        domain
        cdn
        type
        sites {
          language {
            code
            name
          }
          url
        }
        featureAppUsages {
          name
        }
      }
    }
  }
`;
export const ServiceConfig = /* GraphQL */ `
  query ServiceConfig($envName: String, $tenant: String, $language: String) {
    servers(envName: $envName, tenant: $tenant, language: $language) {
      markets {
        sites {
          serviceconfigs {
            name
          }
        }
      }
    }
  }
`;
export const FeatureServiceQueryByServer = /* GraphQL */ `
  query FeatureServiceQueryByServer($envName: String) {
    servers(envName: $envName) {
      featureApps {
        featureServices {
          name
          version
        }
      }
    }
  }
`;
