import { Text, TokenTextAppearance } from "@volkswagen-onehub/components-core";
const StyledErrorBox = styled.div `
  background: rgba(255, 51, 92, 0.1);
  padding: 20px;
`;
import * as React from "react";
import styled from "styled-components";
export const ErrorBox = (props) => {
    return (React.createElement(StyledErrorBox, null,
        React.createElement(Text, { appearance: TokenTextAppearance.copy200 }, props.text)));
};
