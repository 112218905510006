import * as React from "react";
import { CTA } from "@volkswagen-onehub/components-core";
import { withRouter } from "react-router-dom";
function InternalLink(props) {
    const { history, path, text } = props;
    const onClick = (e) => {
        history.push(path);
        e.preventDefault();
    };
    return (React.createElement(CTA, { tag: "a", href: path, emphasis: "tertiary", onClick: onClick }, text));
}
const Link = withRouter(InternalLink);
export { Link };
