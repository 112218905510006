import * as React from "react";
import { FocusLayer, ShimLayer, Container, ContainerGutter, ContainerPadding, TokenTextAppearance, CTA, LayerWrapper, Text, TextTag, FocusLayerSize, } from "@volkswagen-onehub/components-core";
export const ConfirmationLayer = ({ text, headline, handleCancel, handleConfirm, }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(LayerWrapper, { contentCentered: true },
            React.createElement(ShimLayer, { key: "shim" }),
            React.createElement(FocusLayer, { key: "layer", active: true, size: FocusLayerSize.A },
                React.createElement(Container, { gutter: ContainerGutter.static400, horizontalAlign: "center", padding: ContainerPadding.static450, verticalAlign: "center", wrap: "always", stretchContent: true },
                    React.createElement(Container, { gutter: ContainerGutter.static300, wrap: "always" },
                        React.createElement(Text, { bold: true, appearance: TokenTextAppearance.headline300, tag: TextTag.h2 }, headline),
                        React.createElement(Text, null, text),
                        React.createElement(Container, { stretchContent: true, gutter: ContainerGutter.dynamic0300 },
                            React.createElement(CTA, { onClick: handleCancel, tag: "button", emphasis: "secondary", stretchContent: true }, "cancel"),
                            React.createElement(CTA, { onClick: handleConfirm, tag: "button", emphasis: "primary", stretchContent: true }, "confirm"))))))));
};
