import React, { useContext } from "react";
import { StyledTable, StyledTd, StyledTh } from "./Table";
import { ServiceContext } from "../context/ServiceContext";
import { gql } from "../../__generated__";
import { config } from "../queries/config";
import { useQuery } from "@apollo/client";
import { Container, ContainerGutter, ContainerPadding, FocusLayer, FocusLayerSize, LayerWrapper, ShimLayer, } from "@volkswagen-onehub/components-core";
export const AppConfigLayer = () => {
    const layerManager = useContext(ServiceContext).layerManager;
    const configQuery = gql(config);
    const { data } = useQuery(configQuery);
    if (!data) {
        return React.createElement("span", null, "No Data Available");
    }
    const body = (React.createElement("tbody", null,
        renderAemAuthorApiRows("prod", data, true),
        renderAemAuthorApiRows("int", data),
        renderAemAuthorApiRows("stage", data),
        React.createElement("tr", null,
            React.createElement(StyledTd, { rowSpan: 3 }, "aem publisher"),
            React.createElement(StyledTd, null, "prod"),
            React.createElement(StyledTd, { colSpan: 2 },
                " ",
                data.config.aemPublishEnvs.prod)),
        React.createElement("tr", null,
            React.createElement(StyledTd, null, "int"),
            React.createElement(StyledTd, { colSpan: 2 },
                " ",
                data.config.aemPublishEnvs.int)),
        React.createElement("tr", null,
            React.createElement(StyledTd, null, "stage"),
            React.createElement(StyledTd, { colSpan: 2 },
                " ",
                data.config.aemPublishEnvs.stage)),
        React.createElement("tr", null,
            React.createElement(StyledTd, null, "app store"),
            React.createElement(StyledTd, { colSpan: 3 }, data.config.appStore)),
        React.createElement("tr", null,
            React.createElement(StyledTd, null, "oscar"),
            React.createElement(StyledTd, { colSpan: 3 }, data.config.oscar))));
    return (React.createElement(React.Fragment, null,
        React.createElement(LayerWrapper, { contentCentered: true },
            React.createElement(ShimLayer, { key: "shim", onClick: () => layerManager.closeAlert() }),
            React.createElement(FocusLayer, { key: "layer", active: true, size: FocusLayerSize.A },
                React.createElement(Container, { gutter: ContainerGutter.static400, horizontalAlign: "center", padding: ContainerPadding.static450, verticalAlign: "center", wrap: "always", stretchContent: true },
                    React.createElement(StyledTable, null,
                        React.createElement("thead", null,
                            React.createElement("tr", null,
                                React.createElement(StyledTh, null, "endpoint"),
                                React.createElement(StyledTh, { colSpan: 4 }))),
                        body))))));
};
function renderAemAuthorApiRows(env, data, firstEnv = false) {
    const envRowSpan = Object.keys(data.config.aemAuthorApi.prod).length - 1;
    const typeRowSpan = 3 * envRowSpan;
    return Object.entries(data.config.aemAuthorApi[env])
        .filter(([key]) => key !== "__typename")
        .map(([key, value], idx) => {
        return (React.createElement("tr", { key: `${env}_${key}` },
            idx === 0 && firstEnv && (React.createElement(StyledTd, { rowSpan: typeRowSpan }, "aem author api")),
            idx === 0 && React.createElement(StyledTd, { rowSpan: envRowSpan }, env),
            React.createElement(StyledTd, null, key),
            React.createElement(StyledTd, null,
                " ",
                value)));
    });
}
