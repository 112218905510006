/* eslint-disable @typescript-eslint/no-explicit-any */
function addElements(d, field, results) {
    if (!d) {
        return;
    }
    const value = d[field];
    if (Array.isArray(value)) {
        value.forEach((v) => results.push(v));
    }
    else {
        results.push(value);
    }
}
/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function getValues(field, data) {
    if (!field.length) {
        return [data];
    }
    const path = field.split(".");
    return path.reduce((previous, current) => {
        const results = [];
        if (!previous) {
            return results;
        }
        else {
            if (Array.isArray(previous)) {
                previous.forEach((d) => {
                    addElements(d, current, results);
                });
            }
            else {
                addElements(previous, current, results);
            }
        }
        return results;
    }, data);
}
