import React, { useContext } from "react";
import { StyledTable, StyledTd, StyledTh } from "./Table";
import { ArticleMetaInfo, TextBlockWithLabel } from "./ArticleMetaInfo";
import { Container, ContainerGutter, ContainerPadding, FocusLayerSize, FocusLayer, LayerWrapper, ShimLayer, Tabs, } from "@volkswagen-onehub/components-core";
import { ServiceContext } from "../context/ServiceContext";
export const FeatureAppVersionLayer = (props) => {
    const { libs, featureServices, pageIds, serviceConfigurations } = props.version;
    const layerManager = useContext(ServiceContext).layerManager;
    const contents = [];
    featureServices &&
        featureServices.length > 0 &&
        contents.push({
            key: "fs",
            title: "Feature Services",
            content: React.createElement(FeatureServices, { featureServices: featureServices }),
        });
    pageIds &&
        pageIds.length > 0 &&
        contents.push({
            key: "ps",
            title: "Feature Hub Pages",
            content: React.createElement(PageIds, { pageIds: pageIds }),
        });
    serviceConfigurations &&
        serviceConfigurations.length > 0 &&
        contents.push({
            key: "sc",
            title: "Service Configurations",
            content: React.createElement(ServiceConfigs, { configs: serviceConfigurations }),
        });
    libs &&
        contents.push({
            key: "li",
            title: "Libraries",
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            content: React.createElement(Libs, { libs: libs }),
        });
    return (React.createElement(React.Fragment, null,
        React.createElement(LayerWrapper, { contentCentered: true },
            React.createElement(ShimLayer, { key: "shim", onClick: () => layerManager.closeAlert() }),
            React.createElement(FocusLayer, { key: "layer", active: true, size: FocusLayerSize.A },
                React.createElement(Container, { gutter: ContainerGutter.static400, horizontalAlign: "center", padding: ContainerPadding.static450, verticalAlign: "center", wrap: "always", stretchContent: true },
                    React.createElement(ArticleMetaInfo, null,
                        props.version.releasePageUrl && (React.createElement(TextBlockWithLabel, { label: "Release", text: props.version.version, href: props.version.releasePageUrl })),
                        props.includedApps && (React.createElement(TextBlockWithLabel, { label: "Included App", text: props.includedApps.join(", ") }))),
                    React.createElement(Tabs, { idPrefix: "tab", onChange: () => {
                            // no op
                        } }, contents))))));
};
export const FeatureServices = (props) => {
    return (React.createElement(StyledTable, null,
        React.createElement("thead", null,
            React.createElement("tr", null,
                React.createElement(StyledTh, null, "name"),
                React.createElement(StyledTh, null, "version"),
                React.createElement(StyledTh, null, "optional"))),
        React.createElement("tbody", null, [...props.featureServices]
            .sort((f1, f2) => f1.name.localeCompare(f2.name))
            .map((f) => (React.createElement("tr", { key: f.name },
            React.createElement(StyledTd, null, f.name),
            React.createElement(StyledTd, null, f.version),
            React.createElement(StyledTd, null, f.optional ? "yes" : "no")))))));
};
export const ServiceConfigs = (props) => {
    return (React.createElement(StyledTable, null,
        React.createElement("thead", null,
            React.createElement("tr", null,
                React.createElement(StyledTh, null, "id"),
                React.createElement(StyledTh, null, "required"))),
        React.createElement("tbody", null, [...props.configs]
            .sort((s1, s2) => s1.id.localeCompare(s2.id))
            .map((f) => (React.createElement("tr", { key: f.id },
            React.createElement(StyledTd, null, f.id),
            React.createElement(StyledTd, null, f.optional ? "no" : "yes")))))));
};
export const PageIds = (props) => {
    return (React.createElement(StyledTable, null,
        React.createElement("thead", null,
            React.createElement("tr", null,
                React.createElement(StyledTh, null, "name"),
                React.createElement(StyledTh, null, "description"))),
        React.createElement("tbody", null, [...props.pageIds]
            .sort((p1, p2) => p1.defaultId.localeCompare(p2.defaultId))
            .map((f) => (React.createElement("tr", { key: f.defaultId },
            React.createElement(StyledTd, null, f.defaultId),
            React.createElement(StyledTd, null, f.description)))))));
};
export const Libs = (props) => {
    const { libs } = props;
    return (React.createElement(StyledTable, null,
        React.createElement("thead", null,
            React.createElement("tr", null,
                React.createElement(StyledTh, null, "name"),
                React.createElement(StyledTh, null, "version"))),
        React.createElement("tbody", null, Object.keys(libs)
            .filter((k) => k !== "__typename")
            .map((name) => (React.createElement("tr", { key: name },
            React.createElement(StyledTd, null, name.replace("Core", "-core")),
            React.createElement(StyledTd, null, libs[name])))))));
};
