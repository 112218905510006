import * as React from "react";
import { Breadcrumbs } from "./CmsBreadcrumbs";
import { Link } from "./Link";
import { withRouter } from "react-router";
function capitalize(name) {
    return name.substring(0, 1).toUpperCase() + name.substring(1);
}
function label(name, match) {
    if (name.startsWith(":")) {
        return capitalize(match[name.substring(1)]);
    }
    if (breadcrumbConfig[name]) {
        const title = breadcrumbConfig[name].title;
        if (title) {
            return title;
        }
    }
    if (name === "") {
        return "Home";
    }
    return capitalize(name);
}
function pathName(name, match) {
    if (name.startsWith(":")) {
        return match[name.substring(1)];
    }
    return name;
}
const breadcrumbConfig = {
    deploymentset: { title: "Feature App" },
    featureapp: { title: "Feature App" },
};
const pathreplacements = {
    "/deploymentset": "/featureapp",
};
export const BreadCrumbs = withRouter(function BreadCrumbsInternal(props) {
    const { path, match } = props;
    const elements = path === "/" ? [""] : path.split("/");
    return (React.createElement(Breadcrumbs, null, elements.map((l, idx) => {
        let subPath = elements
            .map((name) => pathName(name, match ? match.params : {}))
            .filter((e, i) => i <= idx)
            .join("/");
        if (pathreplacements[subPath]) {
            subPath = pathreplacements[subPath];
        }
        const text = label(l, match ? match.params : {});
        return (React.createElement(Link, { key: `bread-${text}`, path: subPath, text: text, bold: idx === elements.length - 1 }));
    })));
});
