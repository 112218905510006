export const allFeatureApps = /* GraphQL */ `
  query allFeatureApps($start: String, $limit: String) {
    allFeatureApps(start: $start, limit: $limit) {
      configurations {
        name
        servers {
          config {
            name
            version
            path
          }
          server {
            name
            authorUrl
          }
        }
      }
      general {
        title
        name
        group
        team
        repositoryUrl
        appStoreAppId
        deploymentSet {
          name
          description
          releasePageUrl
          team
        }
        shortDescription
        documentationUrl
        generalReleasePageUrl
      }
      latestVersion {
        version
        baseUrl
      }
    }
  }
`;
export const singleFeatureApp = /* GraphQL */ `
  query SingleFeatureApp($appName: String, $deploymentSetName: String) {
    singleFeatureApp(appName: $appName, deploymentSetName: $deploymentSetName) {
      configurations {
        name
        servers {
          config {
            name
            version
            groupName
            path
            baseUrl
            ssrUrl
            url
            team
            schema
          }
          server {
            name
            authorUrl
          }
        }
      }
      general {
        title
        name
        group
        team
        repositoryUrl
        appStoreAppId
        deploymentSet {
          name
          description
          releasePageUrl
          team
        }
        shortDescription
        documentationUrl
        generalReleasePageUrl
      }
      deployments {
        env
        deployment {
          version
          depl_time
        }
      }
      latestVersion {
        name
        publishedAt
        content {
          description
          schema
        }
        releasePageUrl
        generalReleasePageUrl
        pageIds {
          defaultId
        }
        libs {
          componentsCore
          iconsCore
        }
        featureServices {
          name
          optional
          version
        }
        featureApps {
          defaultId
        }
        serviceConfigurations {
          id
        }
        baseUrl
        team
        ssrUrl
        url
        schema
        version
        embeddable
        jiraId
      }
      versions {
        publishedAt
        name

        content {
          description
          schema
        }
        releasePageUrl
        generalReleasePageUrl
        pageIds {
          defaultId
        }
        libs {
          componentsCore
          iconsCore
        }
        featureServices {
          name
          optional
          version
        }
        featureApps {
          defaultId
        }
        serviceConfigurations {
          id
        }
        baseUrl
        ssrUrl
        url
        team
        schema
        version
        embeddable
        jiraId
        issue {
          fields {
            status {
              name
            }
          }
          key
          url
        }
      }
    }
    getUserGroup(type: "FeatureApp") {
      featureAppAdmin {
        stage
        int
        prod
      }
    }
  }
`;
