import React from "react";
import ReactDOM from "react-dom";
import { AppWithAuth } from "./AppWithAuth";
import { ConfigContext } from "./context/ConfigContext";
import { Amplify } from "aws-amplify";
import { createApolloClient } from "./utils/apollo-client";
import { createLogger } from "./logger/HttpLogger";
import { getVersion } from "./getVersion";
fetch("/config.json")
    .then((res) => res.json())
    .then((config) => {
    const logger = createLogger({
        env: config.envName,
        url: "https://logger.vwonehub.io",
        version: getVersion(),
    });
    Amplify.configure(config);
    const client = createApolloClient(config);
    ReactDOM.render(React.createElement(React.StrictMode, null,
        React.createElement(ConfigContext.Provider, { value: { config, client, logger } },
            React.createElement(AppWithAuth, null))), document.getElementById("root"));
})
    .catch((e) => console.error("startup of app failed", e));
