import { filter } from "./filter";
import { getValues } from "./getValues";
import { map } from "./map";
import { sort } from "./sort";
import { renderValue } from "./renderValue";
export function createExtendedColumns(columns) {
    let previousGroupPath = [];
    const extendedColumns = columns.map((current) => {
        const newGroup = !!current.groupKey &&
            (previousGroupPath.length === 0 ||
                current.groupKey !== previousGroupPath[previousGroupPath.length - 1]);
        const currentGroupPath = newGroup && current.groupKey
            ? previousGroupPath.concat([current.groupKey])
            : previousGroupPath;
        previousGroupPath = currentGroupPath;
        const groupKey = currentGroupPath.length > 0
            ? currentGroupPath[currentGroupPath.length - 1]
            : undefined;
        const getValue = (data, filter) => {
            if (typeof current.field === "string") {
                return !data ? data : data[current.field];
            }
            return !data
                ? data
                : current.field(data, filter);
        };
        return {
            header: current.header,
            valuesFromRow: (row, filter) => {
                const values = getValues(currentGroupPath.join("."), row);
                return values.map((data) => getValue(data, filter));
            },
            groupKey,
            invisible: current.invisible,
            sortable: current.sortable,
            render: current.render ||
                ((data, _parent, filter) => renderValue(getValue(data, filter), filter)),
            groupPath: currentGroupPath,
        };
    });
    return extendedColumns;
}
export function processTable(data, meta, defaultSortIndex, sortIndex, ascending, filterValue, visibleColumns, filters) {
    const extendedColumns = createExtendedColumns(meta.columns);
    const sorting = {
        ascending,
        defaultField: extendedColumns[defaultSortIndex].valuesFromRow,
        field: extendedColumns[sortIndex].valuesFromRow,
    };
    const filtering = {
        fields: extendedColumns
            .filter((f) => f.valuesFromRow)
            .map((f) => f.valuesFromRow),
        value: filterValue,
        filters: [],
    };
    const filteredAndSortedData = data
        .filter((d) => {
        const visibleFilterExpressions = extendedColumns
            .filter((_c, idx) => visibleColumns[idx])
            .map((c) => c.valuesFromRow);
        const fields = filtering.fields.filter((f) => visibleFilterExpressions.indexOf(f) >= 0);
        return filter(d, Object.assign(Object.assign({}, filtering), { fields }));
    })
        .map((d) => {
        if (!filters) {
            return d;
        }
        return filters.reduce((result, filter) => {
            if (!result)
                return undefined;
            return filter(result);
        }, d);
    })
        .filter((d) => !!d)
        .sort((d1, d2) => {
        return sort(d1, d2, sorting);
    });
    return map(filteredAndSortedData, extendedColumns, visibleColumns, filterValue);
}
